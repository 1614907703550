<template lang="">
    <div class="login">
        <div class="loginMain">
            <h1>{{ projectName }}</h1>
            <section>
                <a-form :model="formData" @finish="handleLogin" layout="vertical">
                    <a-form-item label="请输入账号">
                        <a-input v-model:value="formData.username" placeholder="请输入账号" size="large"></a-input>
                    </a-form-item>
                    <a-form-item label="请输入密码">
                        <a-input-password v-model:value="formData.password" style="padding:0 10px 0 0" placeholder="请输入密码" size="large"></a-input-password>
                    </a-form-item>
                    <a-form-item>
                        <div class="yzmbox">
                            <a-input v-model:value="formData.yzm" placeholder="验证码" size="large" />
                            <img :src="yzmImg" alt="" @click.stop="refreshYzm" />
                        </div>
                    </a-form-item>
                    <a-form-item>
                        <a-button :class="inAnimation ? 'animated shake' : ''" type="primary" style="width: 100%" size="large" html-type="submit" @animationend="inAnimation = false">
                            登录
                        </a-button>
                    </a-form-item>
                </a-form>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted, } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import md5 from 'js-md5';

export default defineComponent({
    name: "login",
    setup() {
        const { proxy } = getCurrentInstance(); //获取上下文实例，ctx=vue2的this 使用proxy代替ctx，因为ctx只在开发环境有效
        let router = useRouter();
        let store = useStore();

        onMounted(() => {
            refreshYzm();
        });

        // 账号密码登录
        const data = reactive({
            formData: {},
            yzmImg: "",
            inAnimation: false,
        });

        //点击登录
        const handleLogin = () => {
            if (!data.formData.username || !data.formData.password || !data.formData.yzm) {
                data.inAnimation = true;
                proxy.message.error("账号密码验证码不得为空！");
                return;
            }
            proxy.http.post("/login/login", {
                username: data.formData.username,
                password: md5(data.formData.password),
                yzm: data.formData.yzm,
                device: "PC_ADMIN",
            }).then((response) => {
                proxy.message.success("登录成功");
                store.dispatch("setUpdateLoginInfo", response);
                goOverview(response);
            }).catch(() => {
                refreshYzm();
            });
        };

        // 验证码刷新事件
        const refreshYzm = () => {
            data.yzmImg = proxy.domain + "/captcha?" + Math.random();
        };

        //登录跳转
        const goOverview = (row) => {
            router.push({ path: row.role_type == 1 ? "/OverviewManagment" : "VideoSubject" });
        };

        return {
            ...toRefs(data),
            projectName: proxy.projectName,
            handleLogin,
            refreshYzm
        };
    },
});
</script>